.btn {
	position: fixed;
	right: 15px;
	bottom: 15px;
	width: 60px;
	height: 60px;
	background: url('./whatsapp.png') no-repeat center center;
	background-size: cover;
	border-radius: 50%;
	box-shadow: 0 5px 10px rgba(0,0,0,0.1);
	z-index: 9999;

	span {
		color: var(--color-1-text);
		position: absolute;
		right: -10px;
		width: 200px;
		font-size: 14px;
		text-align: center;
		bottom: 35px;
		margin-right: 10px;
		translate: 0 -50%;
		background-color: #FFF;
		padding: 15px;
		border-radius: 5px;
		box-shadow: 0 5px 10px rgba(0,0,0,0.1);
		border: 1px solid #CCC;

		&:after,
		&:before {
			content: '';
			border: 10px solid transparent;
			border-top-color: #FFF;
			position: absolute;
			right: 20px;
			top: calc(100% - 1px);
		}

		&:before {
			border-top-color: #CCC;
			top: 100%;
		}
	}

	.x {
		top: -10px;
		right: -10px;
	}

	&:hover span {
		display: block;
	}
}